@font-face {
  font-family: "TelkomselBatikSans";
  src: url("./font/TelkomselBatikSans-Regular.eot") format("embedded-opentype"),
    url("./font/TelkomselBatikSans-Regular.woff2") format("woff2"),
    url("./font/TelkomselBatikSans-Regular.woff") format("woff"),
    url("./font/TelkomselBatikSans-Regular.ttf") format("truetype"),
    url("./font//TelkomselBatikSans-Regular.svg") format("svg");
  font-weight: "normal";
  font-style: "normal";
}

body {
  margin: 0;
  font-family: "TelkomselBatikSans", cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
