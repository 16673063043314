/* html,
body {
  width: 100%;
  height: 100%;
}
body {
  font-family: "TelkomselBatikSans", cursive;
  background-color: #da251c;
  color: #fff;
  font-size: 14px;
} */
.brand-logo {
  width: 120px;
}
.hero {
  background-color: #da251c;
  color: #fff;
  font-size: 14px;
  min-height: 100%;
  min-width: 100%;
  width: auto;
  height: auto;
  margin: 0;
  position: absolute;
  overflow-x: hidden;
}
.hero:after {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: block;
  left: 0;
  top: 0;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}
.hero .container {
  z-index: 3;
  position: relative;
}
.hero .card {
  background: rgba(0, 0, 0, 0);
}
a,
a:hover {
  color: #7e8388;
  /* text-decoration: underline; */
}
@media (min-width: 576px) {
  section {
    padding: 40px 0px;
  }
  .hero .card .card-block {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}
@media (max-width: 576px) {
  section {
    padding: 20px;
  }
}
.bg-alt {
  background-color: #fff;
}
.btn {
  -webkit-transition: 350ms ease all;
  transition: 350ms ease all;
  text-transform: uppercase;
  font-weight: 500;
  padding: 0.6rem 1.5rem;
  cursor: pointer;
}
.btn:hover,
.btn:focus {
  box-shadow: 6px 6px 25px rgba(0, 0, 0, 0.15);
}
.btn-primary {
  background-color: #00499e !important;
  border-color: #00499e !important;
}
.btn-primary:hover,
.btn-primary:focus {
  color: #fff !important;
}
.form-group {
  margin-bottom: 1.5rem;
}
.form-control {
  height: 45px;
  font-size: 14px;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.5);
  color: #ffffff;
}
.form-control:focus {
  border-color: #ffffff;
  background-color: transparent;
  color: #ffffff;
}
.border-none {
  border: none !important;
  border-color: transparent !important;
}
.text-primary {
  color: #ff5722 !important;
}
.custom-control-input:checked ~ .custom-control-indicator {
  color: #fff;
  background-color: #ff5722;
  outline-color: #ff5722;
}
.content-divider.center {
  text-align: center;
}
.content-divider {
  position: relative;
  z-index: 1;
}
.content-divider > span,
.content-divider > a {
  background-color: #000;
  color: #fff;
  display: inline-block;
  padding: 2px 12px;
  border-radius: 3px;
  text-transform: uppercase;
  font-weight: 500;
}
.content-divider > span:before,
.content-divider > a:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  height: 1px;
  background-color: #ddd;
  width: 100%;
  z-index: -1;
}
