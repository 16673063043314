/* body {
  font-family: "TelkomselBatikSans";
} */

/* body .dashboard {
  font-family: "TelkomselBatikSans", cursive;
} */

.control-ui-button {
  background: none padding-box rgb(255, 255, 255);
  display: block;
  border: 0px;
  margin: 0px;
  padding: 4px;
  text-transform: none;
  appearance: none;
  position: relative;
  cursor: pointer;
  user-select: none;
  direction: ltr;
  overflow: hidden;
  text-align: left;
  color: rgb(0, 0, 0);
  font-family: Roboto, Arial, sans-serif;
  font-size: 11px;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
  font-weight: 500;
  outline: none !important;
}

.control-ui-button:hover {
  background-color: #8b8b8b;
}

.control-ui-button:focus {
  background-color: #8b8b8b;
}

.control-ui-img {
  position: absolute;
  left: 0px;
  top: -144px;
  user-select: none;
  border: 0px;
  padding: 0px;
  margin: 0px;
  max-width: none;
  width: 16px;
  height: 192px;
}

.detail-link:hover {
  text-decoration: underline;
}

.modal-header {
  padding: 5px 15px 5px 15px;
  background: #da251c;
  color: #fff;
}

.modal-header h5 {
  font-size: 1rem;
}

.modal-90w {
  width: 90%;
  max-width: none;
}

.modal-header .close {
  font-size: 1rem;
  color: #fff;
}

.modal-footer {
  padding: 9px;
}

.modal-footer .btn {
  font-weight: 200;
  font-size: 0.9rem;
  padding: 5px 10px 5px 10px;
}

h5 {
  font-size: 1rem;
}

.modal-xl {
  width: 1200px;
  max-width: 1500px;
}

.table td {
  padding: 0 0 0 5px;
  vertical-align: middle;
  font-size: 12px;
}

.table th {
  padding: 0.15rem;
  font-size: 12px;
}

.form-control {
  font-size: 12px;
}

.nav-link {
  display: block;
  padding: 4px 8px 4px 8px;
  font-size: 12px;
}

select.form-control:not([size]):not([multiple]) {
  height: 1 !important;
}

.btn {
  font-size: 12px;
}

.w-100px {
  width: 100px !important;
  margin: 0 !important;
  margin-left: 8px;
}

.w-150px {
  width: 150px !important;
  /* margin: 0 !important; */
  margin-left: 5px;
}

.w-170px {
  width: 170px !important;
  /* margin: 0 !important; */
  margin-left: 5px;
}

li.active {
  pointer-events: none;
  cursor: default;
  color: #000;
}

li.active a {
  color: #000;
}

.card-header {
  padding: 0.55rem 0.75rem;
  background-color: rgba(255, 255, 255, 1);
}

.card {
  background-color: rgba(255, 255, 255, 0.3);
}

.card-block {
  padding: 0.5rem;
  font-size: 0.8rem;
}

.card-block-white {
  padding: 0.5rem;
  background-color: rgba(255, 255, 255, 1);
  font-size: 0.8rem;
}

.si-float-wrapper {
  position: absolute;
  width: 100%;
}
.si-float-wrapper,
.si-float-wrapper * {
  box-sizing: border-box;
}

[class*="si-wrapper"] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 12px;
  cursor: default;
}

.si-wrapper-top {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-top: -40px;
  margin-left: 0px;
  -webkit-transform: translate(-50%, -100%);
  transform: translate(-50%, -100%);
}

.si-wrapper-bottom {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
  margin-top: 0px;
  margin-left: 0px;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

.si-wrapper-left {
  margin-top: -20px;
  margin-left: -11px;
  -webkit-transform: translate(-100%, -50%);
  transform: translate(-100%, -50%);
}

.si-wrapper-right {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  margin-top: -20px;
  margin-left: 11px;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

[class*="si-shadow-wrapper"] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.4;
  z-index: 1;
}

.si-shadow-wrapper-top,
.si-shadow-wrapper-bottom {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.si-shadow-pointer-bottom,
.si-shadow-pointer-right {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1;
}

.si-shadow-frame {
  box-shadow: 20px 20px 0 0 #da251c;
}

[class*="si-shadow-pointer"] {
  position: relative;
  width: 15px;
  height: 15px;
  margin: auto;
}

[class*="si-shadow-inner-pointer"] {
  position: absolute;
  width: 141%;
  height: 141%;
  box-shadow: 0px 28.28427px 0 0 #da251c;
}

.si-shadow-inner-pointer-top {
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg);
}

.si-shadow-inner-pointer-bottom {
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 50%) rotate(-45deg);
  transform: translate(-50%, 50%) rotate(-45deg);
}

.si-shadow-inner-pointer-left {
  top: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg);
}

.si-shadow-inner-pointer-right {
  top: 50%;
  right: 0;
  -webkit-transform: translate(50%, -50%) rotate(-45deg);
  transform: translate(50%, -50%) rotate(-45deg);
}

.si-frame {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  border-radius: 3px;
  overflow: hidden;
  z-index: 2;
}

.si-content-wrapper {
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  padding: 10px;
  background-color: #fff;
}

.si-has-border .si-content-wrapper {
  border: 4px solid #da251c;
}

.si-content {
  overflow: auto;
}

.si-content a {
  color: #000;
}

.si-close-button {
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
  outline: 0 !important;
  background-color: transparent;
  color: inherit;
  font-family: Arial, Baskerville, monospace;
  font-size: 2em;
  cursor: pointer;
  opacity: 0.7;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.si-close-button:hover,
.si-close-button:focus {
  opacity: 1;
}

[class*="si-pointer-border"] {
  position: absolute;
  border: 15px solid transparent;
  z-index: 3;
}

[class*="si-pointer-bg"] {
  position: relative;
  border: 15px solid transparent;
  z-index: 4;
}
.si-has-border [class*="si-pointer-bg"] {
  border-width: 12px;
}

.si-pointer-border-top,
.si-pointer-border-bottom {
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

.si-pointer-border-left,
.si-pointer-border-right {
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.si-pointer-top {
  border-bottom: 0;
}

.si-pointer-border-top {
  bottom: 0;
  border-top-color: #da251c;
}

.si-pointer-bg-top {
  border-top-color: #fff;
}
.si-has-border .si-pointer-bg-top {
  top: -8px;
  margin-bottom: 3px;
}

.si-pointer-bottom {
  border-top: 0;
}

.si-pointer-border-bottom {
  top: 0;
  border-bottom-color: #da251c;
}

.si-pointer-bg-bottom {
  border-bottom-color: #fff;
}
.si-has-border .si-pointer-bg-bottom {
  bottom: -8px;
  margin-top: 3px;
}

.si-pointer-left {
  border-right: 0;
}

.si-pointer-border-left {
  right: 0;
  border-left-color: #da251c;
}

.si-pointer-bg-left {
  border-left-color: #fff;
}
.si-has-border .si-pointer-bg-left {
  left: -8px;
  margin-right: 3px;
}

.si-pointer-right {
  border-left: 0;
}

.si-pointer-border-right {
  left: 0;
  border-right-color: #da251c;
}

.si-pointer-bg-right {
  border-right-color: #fff;
}
.si-has-border .si-pointer-bg-right {
  right: -8px;
  margin-left: 3px;
}

.si-wrapper-top {
  width: 350px;
  max-width: 350px;
}
@media (min-width: 600px) {
  .si-wrapper-top {
    max-width: 350px;
  }
}
@media (min-width: 992px) {
  .si-wrapper-top {
    max-width: 350px;
  }
}

@media (max-width: 991px) {
  .si-frame {
    padding: 50px;
  }
}

@media (max-width: 599px) {
  .si-frame {
    padding: 40px;
  }
}

.si-close-button {
  width: 40px;
  height: 40px;
}

.si-content h1 {
  margin: 20px 0 10px 0;
  font-size: 14px;
}

.si-content p {
  padding: 0 10px 0 10px;
}

.si-content em {
  float: right;
}

.si-content hr {
  margin: 2px 0 0;
  border-color: #da251c;
}

.labels {
  color: red;
  background-color: white;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  width: 110px;
  border: 2px solid black;
  white-space: nowrap;
}

.labels2 {
  color: red;
  background-color: white;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  width: 60px;
  border: 2px solid black;
  white-space: nowrap;
}

.checkbox {
  width: 20px;
  height: 20px;
  /* background: transparent url("../img/unchecked-checkbox.png") no-repeat; */
  background-size: 20px 20px;
}
.checked {
  /* background: transparent url("../img/checked-checkbox.png") no-repeat; */
  background-size: 20px 20px;
}

.checkbox input[type="checkbox"] {
  display: none;
}

.checkbox_all {
  width: 20px;
  height: 20px;
  /* background: transparent url("../img/unchecked-checkbox.png") no-repeat; */
  background-size: 20px 20px;
}
.checked_all {
  /* background: transparent url("../img/checked-checkbox.png") no-repeat; */
  background-size: 20px 20px;
}

.messages {
  padding: 10px;
  height: 280px;
  background-color: green;
  background-color: rgb(237, 239, 244);
  border-width: 0px;
  border-color: black;
  border-style: solid;
  overflow-y: scroll;
}

.messages ul {
  padding: 0px;
  list-style-type: none;
}

.messages ul li {
  height: auto;
  margin-bottom: 10px;
  clear: both;
  padding-left: 10px;
  padding-right: 10px;
}

.messages ul li span {
  display: inline-block;
  max-width: 300px;
  background-color: white;
  padding: 5px;
  border-radius: 4px;
  position: relative;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(237, 239, 244);
  font-size: 14px;
}

.messages ul li span.left {
  float: left;
}

.messages ul li span.left:after {
  content: "";
  display: inline-block;
  position: absolute;
  left: -8.5px;
  top: 7px;
  height: 0px;
  width: 0px;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid white;
}

.messages ul li span.left:before {
  content: "";
  display: inline-block;
  position: absolute;
  left: -9px;
  top: 7px;
  height: 0px;
  width: 0px;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid rgb(237, 239, 244);
}

.messages ul li span.right:after {
  content: "";
  display: inline-block;
  position: absolute;
  right: -8px;
  top: 6px;
  height: 0px;
  width: 0px;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid #2196f3;
}

.messages ul li span.right:before {
  content: "";
  display: inline-block;
  position: absolute;
  right: -9px;
  top: 6px;
  height: 0px;
  width: 0px;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid #2196f3;
}

.messages ul li span.right {
  float: right;
  background-color: #2196f3;
  color: #fff;
}

.messages div.date {
  font-size: 10px;
}

.clear {
  clear: both;
}

.gm-style-iw + div {
  display: none;
}
.gm-style-iw {
  text-align: center;
}
.gm-style-iw + button {
  display: none;
}
.gm-ui-hover-effect {
  display: none;
}

@media (min-width: 768px) {
  .modal-xl {
    width: 90%;
    max-width: 1200px;
  }
}

.card-outsource {
  position: absolute;
  padding: 20px;
  background-color: #fff;
  right: 10px;
  top: 200px;
  z-index: 999;
  border-radius: 10px;
}

/* .logo-eksternal {
    margin-bottom: 1px;
    margin-left : 1px;
    width: 200px;
    height: 65px;
    color: green;
    position: fixed;
    bottom: 0;
  } */
